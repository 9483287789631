<template>
  <div id="Tools" class="tools" v-if="loggedIn">
    <div class="banner-con">
      <div class="top-left-con">
        <div class="line-one" v-visible="false"></div>
        <div class="line-two" v-visible="false"></div>
        <div class="line-three"></div>
        <p class="bg-tit2">
          {{ $t('Tools.title') }}
          <span>
            <img style="width: 90px" src="../assets/img/2-Icons/icons_connect-wh.svg" alt="" />
          </span>
        </p>
      </div>
      <div class="top-right-con"></div>
      <div class="bottom-left-con"></div>
    </div>
    <div class="content">
      <div class="cuhkparagraph">
        <span class="txt">{{ $t('Tools.cuhk_paragraph') }}</span>
      </div>
      <div class="cuhklogo">
        <span>
          <img class="cuhkLogoCentre" style="max-height: 300px" src="../assets/images/cu_logo_4c_centre.png" alt="" />
          <img class="anniversaryLogo" style="max-height: 200px" src="../assets/images/30th Anniversary Logo Design 3.png" alt="" />
        </span>
      </div>
      <div class="content-form">
        <div class="form-container">
          <div class="form-tit">
            <div class="form-tit1">
              {{ $t('Tools.tit1') }}
            </div>
          </div>
          <div class="select-group">
            <el-select v-model="langselect" filterable placeholder="Language" v-if="$i18n.locale == 'en'">
              <el-option v-for="item in Language" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>

            <el-select v-model="langselect" filterable placeholder="語言" v-if="$i18n.locale == 'cn'">
              <el-option v-for="item in Language_cn" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>

          <ul class="word-ul">
            <li class="word-li" v-for="(item, index) in physicalData" :key="index">
              <span style="padding: 13px 0" v-if="checkSelectStudents(item.name, langselect) == true">
                <img class="word-icon" src="../assets/img/2-Icons/icons_file.svg" alt="" />
              </span>

              <span class="txt" v-if="$i18n.locale == 'cn'">
                <span class="txt" style="padding: 13px 0" v-if="langselect == '(英)' && checkSelectStudents(item.name, langselect) == true"> {{ item.name }}</span>
                <span class="txt" style="padding: 13px 0" v-if="langselect == '(中)' && checkSelectStudents(item.name, langselect) == true"> {{ item.name }}</span>
                <span class="txt" style="padding: 13px 0" v-if="langselect == ''"> {{ item.name_zh_tw }}</span>
              </span>

              <span class="txt" v-if="$i18n.locale == 'en'">
                <span class="txt" style="padding: 13px 0" v-if="langselect == '(Eng)' && checkSelectStudents(item.name, langselect) == true"> {{ item.name }}</span>
                <span class="txt" style="padding: 13px 0" v-if="langselect == '(Chi)' && checkSelectStudents(item.name, langselect) == true"> {{ item.name }}</span>
                <span class="txt" style="padding: 13px 0" v-if="langselect == ''"> {{ item.name_en }}</span>
              </span>

              <div class="flex-pdf" v-if="checkSelectStudents(item.name, langselect) == true">
                <a :href="item.path_url" @click="trackCoachingMaterialsClick(item.name, item.path_url)" target="_blank" style="padding: 13px 0">{{ item.type }}</a>
              </div>
            </li>
          </ul>
        </div>

        <div class="form-container">
          <div class="form-tit">
            <div class="form-tit1">
              {{ $t('Tools.tit2') }}
            </div>
          </div>
          <div class="select-group">
            <el-select v-model="langselect1" placeholder="Language" v-if="$i18n.locale == 'en'">
              <el-option v-for="item in Language" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>

            <el-select v-model="langselect1" placeholder="語言" v-if="$i18n.locale == 'cn'">
              <el-option v-for="item in Language_cn" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>

            <el-select v-model="Studentselect" placeholder="Students" v-if="$i18n.locale == 'en'">
              <el-option v-for="item in Students" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>

            <el-select v-model="Studentselect" placeholder="學生" v-if="$i18n.locale == 'cn'">
              <el-option v-for="item in Students_cn" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <ul class="word-ul">
            <li class="word-li" v-for="(item, index) in teachingData" :key="index">
              <span class="txt" v-if="$i18n.locale == 'cn'">
                <span style="padding: 13px 0" v-if="checkSelectStudents(item.name, Studentselect) == true && checkSelectStudents(item.name, langselect1) == true">
                  <img class="word-icon" src="../assets/img/2-Icons/icons_file.svg" alt="" />
                </span>
                <span class="txt" v-if="langselect1 == '(英)' && checkSelectStudents(item.name_zh_tw, langselect1) == true">
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == '小學生' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == '中學生' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == '有特殊學習需要的學生' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == ''"> {{ item.name }} </span>
                </span>

                <span class="txt" v-if="langselect1 == '(中)' && checkSelectStudents(item.name_zh_tw, langselect1) == true">
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == '小學生' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == '中學生' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == '有特殊學習需要的學生' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == ''"> {{ item.name }} </span>
                </span>

                <span class="txt" v-if="langselect1 == ''">
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == '小學生' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == '中學生' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == '有特殊學習需要的學生' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == ''"> {{ item.name }} </span>
                </span>
              </span>

              <span class="txt" v-if="$i18n.locale == 'en'">
                <span style="padding: 13px 0" v-if="checkSelectStudents(item.name_en, Studentselect) == true && checkSelectStudents(item.name, langselect1) == true">
                  <img class="word-icon" src="../assets/img/2-Icons/icons_file.svg" alt="" />
                </span>
                <span class="txt" v-if="langselect1 == '(Eng)' && checkSelectStudents(item.name_en, langselect1) == true">
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == 'Primary School Students' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }}</span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == 'Secondary School Students' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }}</span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == 'SEN Students' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == ''"> {{ item.name }} </span>
                </span>

                <span class="txt" v-if="langselect1 == '(Chi)' && checkSelectStudents(item.name_en, langselect1) == true">
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == 'Primary School Students' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == 'Secondary School Students' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == 'SEN Students' && checkSelectStudents(item.name_en, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == ''"> {{ item.name }} </span>
                </span>

                <span class="txt" v-if="langselect1 == ''">
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == 'Primary School Students' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == 'Secondary School Students' && checkSelectStudents(item.name, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == 'SEN Students' && checkSelectStudents(item.name_en, Studentselect) == true"> {{ item.name }} </span>
                  <span class="txt" style="padding: 13px 0" v-if="Studentselect == ''"> {{ item.name }} </span>
                </span>
              </span>

              <div class="flex-pdf" v-if="checkSelectStudents(item.name, Studentselect) == true && checkSelectStudents(item.name, langselect1) == true">
                <a :href="item.path_url" @click="trackCoachingMaterialsClick(item.name, item.path_url)" target="_blank" style="padding: 13px 0">{{ $t(item.type) }}</a>
              </div>
            </li>
          </ul>
        </div>
        <div class="form-container">
          <div class="form-tit">
            <div class="form-tit1">
              {{ $t('Tools.tit3') }}
            </div>
          </div>
          <div class="word-ul1">
            <li class="word-li" v-for="(item, index) in lessonData" :key="index">
              <span>
                <img class="word-icon" src="../assets/img/2-Icons/icons_file.svg" alt="" />
              </span>
              <span class="txt" v-if="$i18n.locale == 'en'"> {{ $t(item.name_en) }}</span>
              <span class="txt" v-else> {{ $t(item.name_zh_tw) }}</span>
              <div class="flex-pdf">
                <a :href="item.path_url" @click="trackCoachingMaterialsClick(item.name, item.path_url)" target="_blank">{{ $t(item.type) }}</a>
              </div>
            </li>
          </div>
        </div>
        <div class="form-container">
          <div class="form-tit">
            <div class="form-tit1">
              {{ $t('Tools.tit4') }}
            </div>
          </div>
          <div class="word-ul1">
            <div class="word-li" v-for="(item, index) in preventionData" :key="index">
              <span>
                <img class="word-icon" src="../assets/img/2-Icons/icons_file.svg" alt="" />
              </span>
              <span class="txt" v-if="$i18n.locale == 'en'"> {{ $t(item.name_en) }}</span>
              <span class="txt" v-else> {{ $t(item.name_zh_tw) }}</span>
              <div class="flex-pdf">
                <a :href="item.path_url" @click="trackCoachingMaterialsClick(item.name, item.path_url)" target="_blank">{{ $t(item.type) }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="form-container">
          <div class="form-tit">
            <div class="form-tit1">
              {{ $t('Tools.tit5') }}
            </div>
          </div>
          <div class="word-ul1">
            <div class="word-li" v-for="(item, index) in reviewData" :key="index">
              <span>
                <img class="word-icon" src="../assets/img/2-Icons/icons_file.svg" alt="" />
              </span>
              <span class="txt" v-if="$i18n.locale == 'en'"> {{ $t(item.name_en) }}</span>
              <span class="txt" v-else> {{ $t(item.name_zh_tw) }}</span>
              <div class="flex-pdf">
                <a :href="item.path_url" @click="trackCoachingMaterialsClick(item.name, item.path_url)" target="_blank">{{ $t(item.type) }}</a>
              </div>
            </div>
          </div>
        </div>

        <div class="form-container">
          <div class="form-tit">
            <div class="form-tit1">
              {{ $t('Tools.tit6') }}
            </div>
          </div>
          <div class="word-ul1">
            <div class="word-li" v-for="(item, index) in kinesiologyData" :key="index">
              <span>
                <img class="word-icon" src="../assets/img/2-Icons/icons_file.svg" alt="" />
              </span>
              <span class="txt" v-if="$i18n.locale == 'en'"> {{ $t(item.name_en) }}</span>
              <span class="txt" v-else> {{ $t(item.name_zh_tw) }}</span>
              <div class="flex-pdf">
                <a :href="item.path_url" @click="trackCoachingMaterialsClick(item.name, item.path_url)" target="_blank">{{ $t(item.type) }}</a>
              </div>
            </div>
          </div>
        </div>

        <div class="form-container">
          <div class="form-tit">
            <div class="form-tit1">
              {{ $t('Tools.tit7') }}
            </div>
          </div>
          <div class="content-other">
            <div class="content-bg">
              <img width="410px" height="290px" src="../assets/img/0-Banner/sportshub.png" alt="" />
            </div>
            <div class="content-txt">
              <div class="title">{{ $t('Tools.tit7') }}</div>
              <a class="content-visit" href="https://sportscoachhk.inspiringhk.org/#/inspire/sports-films">Visit</a>
            </div>
            <!-- <div class="content-des">{{$t('Tools.desc')}}</div> -->
          </div>
        </div>

        <div class="form-container">
          <div class="form-tit">
            <div class="form-tit1">
              {{ $t('Tools.tit8') }}
            </div>
          </div>
          <div class="content-other">
            <div class="content-bg">
              <img width="410px" height="290px" src="../assets/img/0-Banner/DSC00250.jpg" alt="" />
            </div>
            <div class="content-txt">
              <div class="title">{{ $t('Tools.tit8') }}</div>
              <a class="content-visit" href="https://ihk-academy-web-testing.wistkey.com/#/coach-action/sharing">Visit</a>
            </div>
            <!-- <div class="content-des">{{$t('Tools.desc2')}}</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSports, getResource, getPhysicalResource, getTeachingResource, getLessonResource, getPreventionResource, getReviewResource, getKinesiologyResource } from '@/api/coachTools'
export default {
  name: 'Tolls',
  data() {
    return {
      Language: [
        {
          value: '(Chi)',
          label: 'Chinese',
          item: 'Chinese',
        },
        {
          value: '(Eng)',
          label: 'English',
          item: 'English',
        },
      ],
      Language_cn: [
        {
          value: '(中)',
          label: '中文',
          item: '中文',
        },
        {
          value: '(英)',
          label: '英文',
          item: '英文',
        },
      ],
      Students: [
        {
          value: 'Primary School Students',
          label: 'Primary School Students',
          item: 'Primary School Students',
        },
        {
          value: 'Secondary School Students',
          label: 'Secondary School Students',
          item: 'Secondary School Students',
        },
        {
          value: 'SEN Students',
          label: 'Special Educational Needs Students',
          item: 'Special Educational Needs Students',
        },
      ],
      Students_cn: [
        {
          value: '小學生',
          label: '小學生',
          item: '小學生',
        },
        {
          value: '中學生',
          label: '中學生',
          item: '中學生',
        },
        {
          value: '有特殊學習需要的學生',
          label: '有特殊學習需要的學生',
          item: '有特殊學習需要的學生',
        },
      ],
      value: '',
      Studentselect: '',
      langselect: '',
      langselect1: '',
      item: '',
      loggedIn: false,

      /*select:{
                year:''null'',
                class:null,
            },*/
      physicalData: [],
      teachingData: [],
      lessonData: [],
      preventionData: [],
      reviewData: [],
      kinesiologyData: [],
      sportsData: [],
      resourceData: [],
    }
  },
  mounted() {
    if (this.checkLogin()) {
      this.getPhysicalResource(),
        this.getTeachingResource(),
        this.getLessonResource(),
        this.getPreventionResource(),
        this.getReviewResource(),
        this.getKinesiologyResource(),
        this.getSports(),
        this.getResource()
      this.loggedIn = true
    } else {
      this.$alert(this.$t('Tools.warningtext'), this.$t('Tools.warningtitle'), {
        confirmButtonText: 'OK',
        type: 'warning',
        callback: (action) => {
          this.$router.push('/')
        },
      })
    }
  },
  methods: {
    checkSelectStudents(selected, Student) {
      if (selected.includes(Student)) {
        return true
      } else {
        return false
      }
    },
    Change(val) {
      this.$forceUpdate()
    },
    checkLogin() {
      let token = window.sessionStorage.getItem('token')
      return (this.loggedIn = token == null ? false : true)
    },
    getPhysicalResource() {
      getPhysicalResource().then((res) => {
        this.physicalData = res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1))
      })
    },
    getTeachingResource() {
      getTeachingResource().then((res) => {
        this.teachingData = res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1))
      })
    },
    getLessonResource() {
      getLessonResource().then((res) => {
        this.lessonData = res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1))
      })
    },
    getPreventionResource() {
      getPreventionResource().then((res) => {
        this.preventionData = res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1))
      })
    },
    getReviewResource() {
      getReviewResource().then((res) => {
        this.reviewData = res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1))
      })
    },
    getKinesiologyResource() {
      getKinesiologyResource().then((res) => {
        this.kinesiologyData = res.data.data.sort((a, b) => (a.name > b.name ? 1 : -1))
      })
    },
    getSports() {
      getSports().then((res) => {
        this.sportsData = res.data.data
      })
    },
    getResource() {
      getResource().then((res) => {
        this.resourceData = res.data.data
      })
    },
    trackCoachingMaterialsClick(name, url) {
      console.log('name: ' + name + '&& url: ' + url)
      window.gtag('event', 'coaching_materials_click', {
        event_name: 'coaching_materials-' + name ,
        value: 1,
        event_label: url
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.banner-con {
  position: relative;
  height: 500px;
  .top-left-con {
    width: 92%;
    height: 500px;
    background: #28327b;
    position: absolute;
    top: 0;
    left: 0;
    transform: skew(-15deg) translateX(-9%);
    z-index: 2;
    .line-one {
      width: 338px;
      height: 2px;
      background: #fff;
      position: absolute;
      top: 24%;
      right: 2%;
    }
    .line-two {
      width: 120px;
      height: 2px;
      background: #fff;
      position: absolute;
      top: 42%;
      right: 2%;
    }
    .banner-tit1 {
      width: auto;
      margin-top: 18%;
      margin-left: 2%;
      padding-left: 13%;
      text-align: left;
      font-size: 70px !important;
      color: #fff;
      transform: skew(6deg);
      font-weight: bold;
    }
    .banner-tit2 {
      width: auto;
      margin-top: -2%;
      margin-left: 4%;
      padding-left: 10%;
      text-align: left;
      font-size: 70px !important;
      color: #fff;
      transform: skew(6deg);
      font-weight: bold;
      // font-family: "NotoSansCJKtc-Black";
    }
    .bg-tit1 {
      margin-top: 150px;
      height: 36px;
      font-size: 32px !important;
      font-weight: 400;
      line-height: 42px;
      color: #ffffff;
      transform: skew(13deg);
    }
    .bg-tit2 {
      margin: 0 auto;
      margin-top: 180px;
      font-size: 75px;
      font-weight: bold;
      line-height: 75px;
      color: #ffffff;
      font-size: 70px !important;
      color: #fff;
      opacity: 1;

      transform: skew(13deg);
    }
    .more {
      position: absolute;
      bottom: 20px;
      right: 20px;
      color: #fff;
      transform: skew(15deg);
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .top-right-con {
    width: 21%;
    height: 500px;
    background: #f15922;
    position: absolute;
    right: -5%;
    transform: skew(-15deg) translateX(2.5%);
  }
  .bottom-left-con {
    width: 40%;
    height: 95px;
    background: #f15922;
    position: absolute;
    bottom: -8%;
    left: 0;
    z-index: 2;
    transform: skew(-15deg) translateX(-3%);
  }
}
.content {
  background: url('../assets/img/bg-blue.jpg');
  margin: 0 auto;
  padding-top: 200px;
  padding-bottom: 135px;
  .content-form {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .form-tit {
      position: absolute;
      top: -5%;
      width: 550px;
      background: #28327b;
      transform: skew(-15deg) translateX(12%);
      .form-tit1 {
        font-size: 25px;
        padding: 10px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        transform: skew(13deg);
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
    .form-container {
      position: relative;
      margin: 50px 30px;
      width: 680px;
      height: 694px;
      background: #ffffff;
      box-shadow: 0px 0px 20px #d1d4eb;
      border-radius: 8px;
      .select-group {
        margin: 74px auto 40px auto;
      }
    }
    .text {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .word-ul {
      width: 100%;
      height: 500px;
      overflow: scroll;
      .word-li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 13px;
        text-align: left;
        .word-icon {
          width: 20px;
          margin: 0 30px;
          align-self: center;
        }
        .txt {
          font-size: 20px;
          font-weight: 400;
          color: #1d1d1d;
          width: 80%;
          display: flex;
          justify-content: flex-start;
        }
        .flex-pdf {
          margin: 0 auto;
          align-self: center;
          width: 83px;
          height: 33px;
          background: #f7f8fc;
          border-radius: 18px;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 33px;
          color: #8f98dc;
        }
      }
    }
    .word-ulselected {
      width: 100%;
      height: 500px;
      overflow: scroll;
      .word-li {
        border-bottom: 0.01333rem solid #d1d4eb;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px;
        text-align: left;
        .word-icon {
          width: 20px;
          margin: 0 30px;
          align-self: center;
        }
        .txt {
          font-size: 20px;
          font-weight: 400;
          color: #1d1d1d;
          width: 80%;
          display: flex;
          justify-content: flex-start;
        }
        .flex-pdf {
          margin: 0 auto;
          align-self: center;
          width: 83px;
          height: 33px;
          background: #f7f8fc;
          border-radius: 18px;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 33px;
          color: #8f98dc;
        }
      }
    }
    .word-ul1 {
      width: 100%;
      height: 500px;
      margin-top: 150px;
      overflow: scroll;
      .word-li {
        border-bottom: 0.01333rem solid #d1d4eb;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px;
        text-align: left;
        .word-icon {
          width: 20px;
          margin: 0 30px;
          align-self: center;
        }
        .txt {
          font-size: 20px;
          font-weight: 400;
          color: #1d1d1d;
          width: 60%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .flex-pdf {
          margin: 0 auto;
          align-self: center;
          width: 83px;
          height: 33px;
          background: #f7f8fc;
          border-radius: 18px;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 33px;
          color: #8f98dc;
        }
        .word {
          align-self: center;
          width: 1.10667rem;
          height: 0.44rem;
          background: #f7f8fc;
          border-radius: 0.24rem;
          font-size: 0.21333rem;
          font-weight: 400;
          line-height: 0.44rem;
          color: #8f98dc;
          position: absolute;
          right: 0;
          margin-right: 30px;
        }
      }
    }
    .flex-content {
      width: 80%;
      height: 550px;
      overflow: scroll;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      margin-top: 100px;

      .flex-item {
        width: 149px;
        height: 250px;
        margin: 20px 14px;
        .flex-bg {
          width: 149px;
          height: 180px;
          background: #d1d4eb;
        }
        .flex-pdf {
          margin: 0 auto;
          align-self: center;
          width: 83px;
          height: 33px;
          background: #f7f8fc;
          border-radius: 18px;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 33px;
          color: #8f98dc;
        }
      }
    }
    .content-other {
      width: 80%;
      margin: 0 auto;
      margin-top: 100px;
      .content-bg {
        width: 565px;
        height: 355px;
        background: #d1d4eb;
      }
      .content-txt {
        margin: 120px 0;
        text-align: left;
        font-size: 22px;
        font-weight: bold;
        line-height: 28px;
        color: #28327b;
        .content-visit {
          margin: 0 auto;
          align-self: center;
          width: 83px;
          height: 33px;
          background: #f7f8fc;
          border-radius: 18px;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 33px;
          color: #8f98dc;
          position: absolute;
          right: 50px;
          top: 570px;
        }
      }
      .content-des {
        text-align: left;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: #1d1d1d;
      }
    }
    .form-container1 {
      position: relative;
      margin: 50px 30px;
      width: 100%;
      height: 445px;
      background: #ffffff;
      box-shadow: 0px 0px 20px #d1d4eb;
      border-radius: 8px;
      .form-tit {
        left: 25%;
      }
      .word-ul {
        margin-top: 100px;
        height: 350px;
        .word {
          margin-left: 1000px;
        }
      }
    }
  }
}
.el-select {
  width: 229px;
  height: 52px;
  background: #ffffff;
  margin: 0 10px;
}

.cuhkparagraph {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 0px;
  font-size: 26px;
  margin: 60px;
}
.anniversaryLogo {
  padding-bottom: 63px;
  padding-right: 110px;
}
.cuhkLogoCentre {
  padding-right: 60px;
}

::v-deep {
  .el-input.is-active .el-input__inner,
  .el-input__inner:focus {
    background: #fff;
    border-color: #8f98dc;
  }
}
</style>
