import request from '../utils/request'

export function getSports (params) {
  return request({
    url: '/activity-hubs',
    method: 'GET',
    params
  })
}

export function getResource (params) {
  return request({
    url: '/resource-hubs',
    method: 'GET',
    params
  })
}

export function getPhysicalResource () {
  return request({
    url: '/coach-resources',
    method: 'GET',
    params : {
      'resource_type' : 'physical_resource'
    }
  })
}

export function getTeachingResource () {
  return request({
    url: '/coach-resources',
    method: 'GET',
    params: {
      'resource_type' : 'teaching_resource'

    }
  })
}

export function getLessonResource () {
  return request({
    url: '/coach-resources',
    method: 'GET',
    params:{
      'resource_type' : 'lesson_resource'
    }
  })
}



export function getPreventionResource () {
  return request({
    url: '/coach-resources',
    method: 'GET',
    params: {
      'resource_type' : 'prevention_resource'
    }
  })
}

export function getReviewResource () {
  return request({
    url: '/coach-resources',
    method: 'GET',
    params: {
      'resource_type' : 'review_resource'
    }
  })
}

export function getKinesiologyResource () {
  return request({
    url: '/coach-resources',
    method: 'GET',
    params: {
      'resource_type' : 'kinesiology_resource'
    }
  })
}




